<template>
  <div class="identity">
    <div class="banner">
      <div class="title">
        <img src="~@assets//images/identity/title@2x.png" />
        <p>完成摩点认证，提高征信，独享特权</p>
      </div>
      <img src="~@assets//images/identity/banner@2x.png" />
    </div>
    <div class="enter">
      <div class="content">
        <div class="title">
          <p>选择账号认证类型</p>
          <span>类型认证提交后，不可变更！</span>
        </div>
        <div class="tips"><i class="icon-info2"></i>重要提示：提现时的账户名称默认使用认证姓名，审核通过后不可修改</div>
        <div class="tab">
          <div class="door person">
            <p>个人认证</p>
            <span>个人用户真实身份认证</span>
            <el-button type="primary" @click.stop="get_auth_info('person')">立即认证</el-button>
          </div>
          <div class="door company">
            <p>企业认证</p>
            <span>合法企业的身份认证</span>
            <el-button type="primary" @click.stop="get_auth_info('company')">立即认证</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title">认证步骤</div>
      <div class="step">
        <div>
          <img src="~@assets//images/identity/1@2x.png" />
          <p><i class="icon-page"></i>认证资料准备</p>
        </div>
        <el-icon><Right /></el-icon>
        <div>
          <img src="~@assets//images/identity/2@2x.png" />
          <p><i class="icon-orders"></i>提交审核</p>
        </div>
        <el-icon><Right /></el-icon>
        <div>
          <img src="~@assets//images/identity/3@2x.png" />
          <p><img src="~@assets//images/identity/step4@2x.png" />审核通过</p>
        </div>
        <el-icon><Right /></el-icon>
        <div>
          <img src="~@assets//images/identity/4@2x.png" />
          <p><img src="~@assets//images/identity/step3@2x.png" />按时年审(个人除外)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import domain from '@/service/domain'
import { get } from "@/utils/apiBase.js";
import { mapState } from "vuex";
import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),
  },
  components: {

  },
  mounted() {
    
  },
  methods: {
    // 初次进来，获取认证信息
    async get_auth_info(type) {
      const { status, data, message } = await get("/apis/userauth/query/info", {});
      if (status == 0) {
        if (data.auth_status == 200){
          if(data.person_auth_info && Number(data.person_auth_info.summary_auth) == 1){
                this.goToNextStep(type)
            }else{
                ElMessageBox.confirm(
                  "本账号已经认证了，不可重复认证",
                  "提示",
                  {
                    showCancelButton: false,
                    confirmButtonText: "确定",
                  }
                ).then(() => {});
            }
        }else if(data.auth_status == 100) {
          location.href = domain.zhongchou + '/auth/auth_funds_success';
        }else{
          this.goToNextStep(type)
        }
      }else{
        this.$alert(message, '', {
          confirmButtonText: '确定',
          showClose: false,
          center: true,
          callback: () => {
            
          }
        });
      }
    },
    goToNextStep(type){
      switch(type){
        case 'public':
            window.location.href = domain.zhongchou + '/auth/auth_pub_funds';
            break;
        case 'person':
            ElMessageBox.confirm(
              '<p style="padding: 10px 0 16px 0;">根据《电子商务法》的相关规定，电子商务经营者依法办理市场主体登记。</p>'+
              '<p>对于下述类型的经营者，不需要办理市场主体登记：</p>'+
              '<div style="font-size:14px;padding:11px 0;"><p>（1）个人销售自产农副产品；</p>'+
              '<p>（2）家庭手工业产品;</p>'+
              '<p>（3）个人利用自己的技能从事依法无须取得许可的便民劳务活动（个人通过网络从事保洁、洗涤、缝纫、理发、搬家、配制钥匙、管道疏通、家电家具修理修配等依法无须取得许可的便民劳务活动）和零星小额交易活动（个人从事网络交易活动，年交易额累计不超过10万元的。同一经营者在同一平台或者不同平台开设多家网店的，各网店交易额合并计算）；</p>'+
              '<p>（4）其他依照法律、行政法规不需要进行登记的情况。</p></div>'+
              '<p style="padding-bottom:16px;">如您属于不需要办理市场主体登记的情形，请点击<span style="font-weight:bold;">“我不需要办理”</span>进入下一步。如您属于需要办理市场主体登记的情形，请点击<span style="font-weight:bold;">“我需要办理”</span>查看市场主体登记相关问题。</p>',
              "市场主体登记信息提示",
              {
                distinguishCancelAndClose: true,
                dangerouslyUseHTMLString: true,
                confirmButtonText: "我不需要办理",
                cancelButtonText: "我需要办理",
              }
            )
            .then(() => {
              window.location.href = domain.zhongchou + '/auth/auth_person';
            })
            .catch((action) => {
              if(action == 'cancel'){
                window.location.href = domain.zhongchou + '/about/index/2/9.html';
              }
            });
            break;
        case 'company':
            window.location.href = domain.zhongchou + '/auth/auth_company';
            break;
        case 'no_public':
            window.location.href = domain.zhongchou + '/auth/auth_nopub_funds';
            break;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.identity{
  background: #FFFFFF;
  .banner{
    width:1080px;
    margin:0 auto;
    padding:40px 0;
    display:flex;
    align-items:center;
    justify-content:space-between;
    img{
      width:416px;
    }
    .title{
      img{
        width:244px;
      }
      p{
        font-size: 16px;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 16px;
      }
    }
  }
  .enter{
    background: #F8F8F8;
    padding:40px 0;
    .content{
      width:1080px;
      margin:0 auto;
      .title{
        display:flex;
        justify-content:space-between;
        align-items:baseline;
        padding-bottom:20px;
        p{
          margin:0;
          font-size: 24px;
          font-weight: 600;
          color: #1A1A1A;
          line-height: 24px;
        }
        span{
          font-size: 16px;
          font-weight: 400;
          color: #1A1A1A;
          line-height: 16px;
        }
      }
      .tips{
        display:flex;
        align-items:center;
        padding:16px 15px;
        width: 1080px;
        height: 48px;
        background: #FFF8EE;
        border-radius: 4px 4px 4px 4px;
        font-size: 16px;
        font-weight: 400;
        color: #F5A728;
        line-height: 16px;
        i{
          padding-right:6px;
        }
      }
      .tab{
        margin-top:20px;
        display:flex;
        justify-content:space-between;
        .door{
          display:flex;
          flex-direction: column;
          width: 520px;
          height: 200px;
          padding: 40px 60px 40px 40px;
          &.person{
            background: #fff url("~@assets//images/identity/person@2x.png") no-repeat 400px center;
            background-size:62px 71px;
          }
          &.company{
            background: #fff url("~@assets//images/identity/company@2x.png") no-repeat 400px center;
            background-size:62px 65px;
          }
          p{
            margin:0;
            font-size: 24px;
            font-weight: 600;
            color: #1A1A1A;
            line-height: 24px;
          }
          span{
            padding:20px 0;
            font-size: 16px;
            font-weight: 400;
            color: #1A1A1A;
            line-height: 16px;
          }
          .el-button{
            width:94px;
          }
        }
      }
    }
  }
  .bottom{
    width:1080px;
    margin:0 auto;
    .title{
      padding-top:40px;
      font-size: 24px;
      font-weight: 600;
      color: #1A1A1A;
      line-height: 24px;
    }
    .step{
      padding:80px 0 80px 60px;
      display:flex;
      align-items:end;
      justify-content:space-around;
      >.el-icon{
        font-size:26px;
      }
      div{
        display:flex;
        flex-direction: column;
        align-items:center;
        >img{
          width:88px;
          height:44px;
        }
        p{
          margin:0;
          padding-top:14px;
          display:flex;
          align-items:center;
          font-size: 16px;
          font-weight: 400;
          color: #1A1A1A;
          line-height: 16px;
          i{
            padding-right:10px;
          }
          img{
            width:17px;
            height:auto;
            margin-right:10px;
          }
        }
      }
    }
  }
}
</style>
